import { localStorageConstants } from "./helperContainer";

export const setStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getStorage = (key) => {
  return localStorage.getItem(key);
};

export const clearStorage = (key) => {
  localStorage.removeItem(key);
};

export const clearMenuStorage = () => {
  const storageArray = ["menuItems"];
  for (const storageItem of storageArray) {
    localStorage.removeItem(storageItem);
  }
};

export const clearAllStorages = () => {
  for (const storageItem of localStorageConstants) {
    localStorage.removeItem(storageItem);
  }
};
