import axios from "axios";
import { toastMessage } from "./toastMessage";
import { enumMessageType } from "./helperContainer";
import { clearAllStorages } from "./storageHelper";
import { clearDefRoute, getAuthToken } from "./helperMethods";
import {
  DataSubmittedSuccessfullyText,
  ErrorOccurredText,
  SessionExpiredText,
} from "./messageContainer";
import { getCookie } from "./csrfService";

const getHeaders = (cookieName: string) => {
  const headers = {
    Authorization: `bearer ${getAuthToken()}`,
    [cookieName]: `${getCookie(cookieName)}`,
  };
  return headers;
};

const getHeadersGet = () => {
  const headers = {
    Authorization: `bearer ${getAuthToken()}`,
  };
  return headers;
};

export const get = async (
  baseUrl: string,
  queryUrl: string,
  toastMsgReq: boolean,
  cookieName: string
) => {
  try {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    const response: any = await axios.get(`${baseUrl}${queryUrl}`, {
      headers: getHeadersGet(),
    });
    if (response.status === 200 || response.status === 201) {
      const json = response.data;
      return { data: json };
    } else {
      //  Some methods required toast messages to display after the API response
      if (toastMsgReq === true) {
        toastMessage(enumMessageType.Error, ErrorOccurredText);
      }
    }
  } catch (err) {
    if (toastMsgReq === true) {
      errorBlock(err);
    }
  }
};

export const getReturnError = async (
  baseUrl: string,
  queryUrl: string,
  cookieName: string
) => {
  try {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    const response: any = await axios.get(`${baseUrl}${queryUrl}`, {
      headers: getHeadersGet(),
    });
    if (response.status === 200 || response.status === 201) {
      const json = response.data;
      return { data: json };
    } else {
      toastMessage(enumMessageType.Error, ErrorOccurredText);
    }
  } catch (err) {
    return { data: err };
  }
};

export const post = async (
  baseUrl: string,
  queryUrl: string,
  model: any,
  contentType: string,
  toastMsgReq: boolean,
  cookieName: string
) => {
  axios.defaults.withCredentials = true;
  axios.defaults.headers.post["Content-Type"] = contentType;
  try {
    const response = await axios.post(`${baseUrl}${queryUrl}`, model, {
      headers: getHeaders(cookieName),
    });
    if (response.status === 200 || response.status === 201) {
      //  Some methods required toast messages to display after the API response
      if (toastMsgReq === true) {
        toastMessage(enumMessageType.Success, DataSubmittedSuccessfullyText);
      }
      const json = response;
      return { data: json };
    } else {
      //  Some methods required toast messages to display after the API response
      if (toastMsgReq === true) {
        toastMessage(enumMessageType.Error, ErrorOccurredText);
      }
    }
  } catch (err) {
    if (toastMsgReq === true) {
      errorBlock(err);
    }
  }
};

export const postWithCustMesg = async (
  baseUrl: string,
  queryUrl: string,
  model: any,
  contentType: string,
  toastMsgReq: boolean,
  cookieName: string,
  customMsg: string
) => {
  axios.defaults.withCredentials = true;
  axios.defaults.headers.post["Content-Type"] = contentType;
  try {
    const response = await axios.post(`${baseUrl}${queryUrl}`, model, {
      headers: getHeaders(cookieName),
    });
    if (response.status === 200 || response.status === 201) {
      if (toastMsgReq === true) {
        toastMessage(enumMessageType.Success, customMsg);
      }
      const json = response;
      return { data: json };
    } else {
      if (toastMsgReq === true) {
        toastMessage(enumMessageType.Error, ErrorOccurredText);
      }
    }
  } catch (err) {
    if (toastMsgReq === true) {
      errorBlock(err);
    }
  }
};

export const put = async (
  baseUrl: string,
  queryUrl: string,
  model: any,
  contentType: string,
  toastMsgReq: boolean,
  cookieName: string
) => {
  try {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.post["Content-Type"] = contentType;
    const response = await axios.put(`${baseUrl}${queryUrl}`, model, {
      headers: getHeaders(cookieName),
    });
    if (response.status === 200 || response.status === 201) {
      //  Some methods required toast messages to display after the API response
      if (toastMsgReq === true) {
        toastMessage(enumMessageType.Success, DataSubmittedSuccessfullyText);
      }
      const json = response;
      return { data: json };
    } else {
      //  Some methods required toast messages to display after the API response
      if (toastMsgReq === true) {
        toastMessage(enumMessageType.Error, ErrorOccurredText);
      }
    }
  } catch (err) {
    if (toastMsgReq === true) {
      errorBlock(err);
    }
  }
};

export const downloadFile = async (
  baseUrl: string,
  queryUrl: string,
  model: any,
  fileName: string,
  cookieName: string
) => {
  try {
    axios.defaults.withCredentials = true;
    const response = await axios.post(`${baseUrl}${queryUrl}`, model, {
      headers: getHeaders(cookieName),
      responseType: "blob",
    });
    if (response.status === 200 || response.status === 201) {
      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      }
    } else {
      toastMessage(enumMessageType.Error, ErrorOccurredText);
    }
  } catch (err) {
    errorBlock(err);
  }
};

export const uploadFile = async (
  baseUrl: string,
  queryUrl: string,
  model: any,
  cookieName: string
) => {
  const headers = {
    Accept: "application/json",
    Authorization: `bearer ${getAuthToken()}`,
    [cookieName]: `${getCookie(cookieName)}`,
  };
  try {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    axios.defaults.withCredentials = true;
    const response = await axios.post(`${baseUrl}${queryUrl}`, model, {
      headers: headers,
    });
    if (response.status === 200) {
      if (response.data.success === false) {
        toastMessage(
          enumMessageType.Error,
          response.data.errors.errorData[0].message
        );
      } else {
        toastMessage(enumMessageType.Success, DataSubmittedSuccessfullyText);
      }
      const json = response.data;
      return { data: json };
    }
    if (response.status === 500) {
      toastMessage(enumMessageType.Error, ErrorOccurredText);
    }
  } catch (err) {
    uploadError(err);
  }
};

export const uploadFileNoReturn = async (
  baseUrl: string,
  queryUrl: string,
  model: any,
  cookieName: string
) => {
  const headers = {
    Accept: "application/json",
    Authorization: `bearer ${getAuthToken()}`,
    [cookieName]: `${getCookie(cookieName)}`,
  };
  try {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post(`${baseUrl}${queryUrl}`, model, {
      headers: headers,
    });
    if (response.status === 200) {
      const json = response.data;
      return { data: json };
    }
  } catch (err) {
    uploadError(err);
  }
};

export const regenerateToken = async (
  baseUrl: string,
  queryUrl: string,
  model: any,
  customMsg: string,
  cookieName: string
) => {
  axios.defaults.withCredentials = false;
  try {
    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
    const response = await axios.post(`${baseUrl}${queryUrl}`, model, {
      headers: getHeaders(cookieName),
    });
    if (response.status === 200 || response.status === 201) {
      const json = response;
      return { data: json };
    } else {
      clearDefRoute();
      clearAllStorages();
      toastMessage(enumMessageType.Error, SessionExpiredText);
      window.location.href = "/logout";
    }
  } catch (err) {
    clearDefRoute();
    clearAllStorages();
    toastMessage(enumMessageType.Error, SessionExpiredText);
    window.location.href = "/logout";
  }
};

export const get3rdParty = async (url: string) => {
  try {
    axios.defaults.withCredentials = false;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    const response: any = await axios.get(url);
    if (response.status === 200 || response.status === 201) {
      const json = response.data;
      return { data: json };
    } else {
      return { data: "" };
    }
  } catch (err) {}
};

const uploadError = (err: any) => {
  if (err.response.status === 401) {
    toastMessage(enumMessageType.Error, SessionExpiredText);
    window.location.href = "/logout";
  } else {
    toastMessage(enumMessageType.Error, ErrorOccurredText);
    return { data: "" };
  }
};

const errorBlock = (err: any) => {
  if (err.response) {
    if (err.response.status === 404) {
      if (err.response.data.errors) {
        const errorDec = err.response.data.errors[0].description;
        if (errorDec) {
          toastMessage(enumMessageType.Error, errorDec);
        } else {
          toastMessage(enumMessageType.Error, err.response.data.errors[0]);
        }
      } else if (err.response.data) {
        toastMessage(enumMessageType.Error, err.response.data[0].description);
      } else {
        toastMessage(enumMessageType.Error, ErrorOccurredText);
      }
    } else if (err.response.status === 401) {
      toastMessage(enumMessageType.Error, SessionExpiredText);
      window.location.href = "/logout";
    } else if (err.response.status === 500) {
      if (err.response.data && err.response.data.length > 0) {
        toastMessage(enumMessageType.Error, err.response.data[0].description);
      } else if (err.response.statusText) {
        toastMessage(enumMessageType.Error, err.response.statusText);
      } else {
        toastMessage(enumMessageType.Error, ErrorOccurredText);
      }
    } else if (err.response.status === 400) {
      if (err.response.statusText) {
        toastMessage(enumMessageType.Error, err.response.statusText);
      } else {
        toastMessage(enumMessageType.Error, ErrorOccurredText);
      }
    } else {
      toastMessage(enumMessageType.Error, ErrorOccurredText);
    }
  } else {
    toastMessage(enumMessageType.Error, ErrorOccurredText);
  }
};

export const carletonPut = async (
  baseUrl: string,
  queryUrl: string,
  model: any,
  contentType: string,
  toastMsgReq: boolean,
  cookieName: string
) => {
  try {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.post["Content-Type"] = contentType;
    const response = await axios.put(`${baseUrl}${queryUrl}`, model, {
      headers: getHeaders(cookieName),
    });
    if (response.status === 200 || response.status === 201) {
      const json = response;
      return { data: json };
    } else {
      //  Some methods required toast messages to display after the API response
      if (toastMsgReq === true) {
        toastMessage(enumMessageType.Error, ErrorOccurredText);
      }
    }
  } catch (err) {
    if (toastMsgReq === true) {
      errorBlock(err);
    }
  }
};

export const carletonPost = async (
  baseUrl: string,
  queryUrl: string,
  model: any,
  contentType: string,
  toastMsgReq: boolean,
  cookieName: string
) => {
  axios.defaults.withCredentials = true;
  axios.defaults.headers.post["Content-Type"] = contentType;
  try {
    const response = await axios.post(`${baseUrl}${queryUrl}`, model, {
      headers: getHeaders(cookieName),
    });
    if (response.status === 200 || response.status === 201) {
      const json = response;
      return { data: json };
    } else {
      //  Some methods required toast messages to display after the API response
      if (toastMsgReq === true) {
        toastMessage(enumMessageType.Error, ErrorOccurredText);
      }
    }
  } catch (err) {
    if (toastMsgReq === true) {
      errorBlock(err);
    }
  }
};
