import React, { useEffect, useMemo, useState } from "react";
import { useHtmlClassService } from "../../_core/VerosCreditLayout";
import {
  underWritingPhoneNumbers,
  enumUsrTypes,
} from "../../../../app/common/helperContainer";
import { getDealersById } from "../../../../app/common/commonServices";
import { useSelector } from "react-redux";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();
  const { user } = useSelector((state) => state.auth);
  const [defaultPhoneNo, setDefaultPhoneNo] = useState(
    underWritingPhoneNumbers.DefaultNo
  );

  useEffect(() => {
    checkUnPhNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUnPhNumber = async () => {
    let dealerId = "";
    if (user && user[1].userType === enumUsrTypes.UsrSv) {
      dealerId =
        user[1].assDealer.length > 0
          ? user[1].assDealer.filter((o) => o.isDefault === true)[0].dealerId
          : "";

      if (dealerId !== "") {
        const data = await getDealersById(dealerId);
        if (data?.data.length > 0) {
          if (data?.data[0].type.toLowerCase().includes("franchise"))
            setDefaultPhoneNo(underWritingPhoneNumbers.FranchiseNo);
          else if (
            data?.data[0].type.toLowerCase().includes("independent") &&
            (data?.data[0].branch.toLowerCase().includes("eastern") ||
              data?.data[0].branch.toLowerCase().includes("central"))
          )
            setDefaultPhoneNo(underWritingPhoneNumbers.IndependentEaOrCeNo);
          else if (
            data?.data[0].type.toLowerCase().includes("independent") &&
            data?.data[0].branch.toLowerCase().includes("western")
          )
            setDefaultPhoneNo(underWritingPhoneNumbers.IndependentWestNo);
        }
      }
    }
  };

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-1 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted mr-2">{today.toString()}</span> &copy;{" "}
          <span className="text-dark-75">DealLane</span>
        </div>
        <div className="text-dark nav nav-dark order-1 order-md-2">
          <table>
            <tbody>
              <tr className="footerTd">
                <th
                  style={{ color: "#0B679E", textAlign: "center" }}
                  colSpan={"3"}
                >
                  QUESTIONS? Feel free to contact us
                </th>
              </tr>
              <tr className="footerTd footerLineheight">
                <td className="footerTdAlign">Funding:</td>
                <td>844-695-1770&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>Funding@veroscredit.com</td>
              </tr>
              <tr className="footerTd footerLineheight">
                <td className="footerTdAlign">Underwriting:</td>
                <td>{defaultPhoneNo}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>Credit@veroscredit.com</td>
              </tr>
              <tr className="footerTd footerLineheight">
                <td className="footerTdAlign">Fax:</td>
                <td>844-695-1772&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>VerosVr@veroscredit.com</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    // <div
    //   className={`footer bg-white py-1 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
    //   id="kt_footer"
    // >
    //   <div
    //     className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
    //   >
    //     <div className="text-dark order-2 order-md-1" style={{ width: "45%" }}>
    //       <span className="text-muted mr-2">{today.toString()}</span> &copy;{" "}
    //       <span className="text-dark-75">DealLane</span>
    //     </div>
    //     <div className="text-dark order-2 order-md-1" style={{ width: "55%" }}>
    //       <table>
    //         <tbody>
    //           <tr className="footerTd">
    //             <th
    //               style={{ color: "#0B679E", textAlign: "center" }}
    //               colSpan={"2"}
    //             >
    //               QUESTIONS? Feel free to contact us
    //             </th>
    //           </tr>
    //           <tr className="footerTd footerLineheight">
    //             <td className="footerTdAlign">Funding:</td>
    //             <td>
    //               844-695-1777&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Stips@veroscredit.com
    //             </td>
    //           </tr>
    //           <tr className="footerTd footerLineheight">
    //             <td className="footerTdAlign">Underwriting:</td>
    //             <td>
    //               888-224-2975&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FundsRelease@veroscredit.com
    //             </td>
    //           </tr>
    //           <tr className="footerTd footerLineheight">
    //             <td className="footerTdAlign">Fax:</td>
    //             <td>
    //               844-695-1772&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VerosVr@veroscredit.com
    //             </td>
    //           </tr>
    //         </tbody>
    //       </table>
    //     </div>
    //   </div>
    // </div>
  );
}
