import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { defRtTime } from "../helperContainer";
import { clearDefRoute } from "../helperMethods";

const ClearDefaultRoute = () => {
  const handleDefRt = () => {
    clearDefRoute();
  };

  useIdleTimer({
    timeout: defRtTime,
    onIdle: handleDefRt,
  });

  return <></>;
};

export default ClearDefaultRoute;
