import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_veroscredit/_helpers";
import { Link } from "react-router-dom";
import { forgetPassword } from "../_redux/authCrud";
import { toastMessage } from "../../../../app/common/toastMessage";
import {
  enumMessageType,
  newEmailRegExp,
} from "../../../../app/common/helperContainer";
import { useHistory } from "react-router-dom";
import {
  clearDefRoute,
  ConstructRecaptcha,
} from "../../../common/helperMethods";
import { ResetPassSuccessText } from "../../../common/messageContainer";
import { googleReSiteKey } from "../../../common/apiURLs";
import CircleSpinner from "../../../common/loader/circleSpinner";

const ForgotPassword = (prop) => {
  const history = useHistory();
  const [isLoader, setIsLoader] = useState(true);
  const intValues = {
    email: "",
  };

  useEffect(() => {
    clearDefRoute();
    // Add reCaptcha
    ConstructRecaptcha();
    setIsLoader(false);
  }, []);

  const handleOnSubmit = (values) => {
    sendResetPwdLink(values);
  };

  const sendResetPwdLink = async (values) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(googleReSiteKey, { action: "submit" })
        .then((token) => {
          setIsLoader(true);
          forgetPassword(values.email, token).then(({ data }) => {
            if (data === true) {
              toastMessage(enumMessageType.Success, ResetPassSuccessText);
              setIsLoader(false);
              history.push("/auth/login");
            } else if (data === false) {
              toastMessage(enumMessageType.Error, "Failed google recaptcha.");
              setIsLoader(false);
            } else {
              setIsLoader(false);
            }
          });
        });
    });
  };

  const dvContWd = {
    width: "250px",
  };

  return (
    <div>
      <div className={`${isLoader === true ? "loaderGrey" : "loaderDispaly"}`}>
        <CircleSpinner />
      </div>
      <Formik
        initialValues={intValues}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid format")
            .required("Enter Email Address")
            .min(3, "Minimum 3 characters")
            .max(50, "Maximum 50 characters")
            .matches(newEmailRegExp, "Invalid format")
            .nullable(),
        })}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          handleChange,
          errors,
          handleBlur,
          resetForm,
        }) => (
          <>
            <div className="card-custom">
              <div className="text-center">
                <img
                  src={toAbsoluteUrl("/media/svg/vc-blue-and-grey.svg")}
                  alt="Logo"
                  style={{ height: "55px" }}
                />
              </div>
              <div className="form">
                <div className="tab-content">
                  <Form
                    noValidate
                    onSubmit={handleSubmit}
                    style={{ padding: "70px" }}
                  >
                    <div className="form-group row">
                      <div className="col-lg-12 col-xl-12 text-center mb-5">
                        <h3 className="font-size-h2">Forgot your Password?</h3>
                        <div style={dvContWd}>
                          Enter the email address you use to log in to this
                          site. If there is an account associate with that email
                          address, we will email you a link to reset your
                          password. Make sure to check your spam.
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-12 col-xl-12">
                        <Form.Control
                          type="text"
                          placeholder="Email Address"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-12 text-center mt-10">
                        <button
                          type="submit"
                          className={`btn btn-primary btn-saveChanges px-8 py-3 my-3 mx-3`}
                        >
                          Submit
                        </button>
                        <Link to="/auth">
                          <button
                            type="button"
                            id="kt_login_forgot_cancel"
                            className="btn btn-light-primary px-8 py-3 my-3 mx-3"
                          >
                            Cancel
                          </button>
                        </Link>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
