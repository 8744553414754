import React from "react";

export function ScrollTop() {
  return (
    <div id="kt_scrolltop" className="scrolltop">
      <i
        className="fa fa-arrow-up"
        style={{ color: "white", fontSize: "20px" }}
      ></i>
    </div>
  );
}
