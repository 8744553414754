import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import {
  login,
  verifyMfaOtp,
  saveTempMobile,
  cancelTempMobile,
  sendMFA,
} from "../_redux/authCrud";
import { toAbsoluteUrl } from "../../../../_veroscredit/_helpers";
import {
  dwret,
  enumDataTableTabs,
  dwat,
  brwId,
  enumMessageType,
  enumMFATypes,
  newEmailRegExp,
  invalidCredentialId,
  threeFailedOTPAttemptsId,
  invalidMFAId,
  accountLockedId,
  invalidMFAOTPId,
  oTPExpiredId,
  noMobileNumberId,
  registeredAsALandLineId,
  googleRecaptchaId,
  defaultPageNumber,
  defaultSortColumn,
  defaultSortOrder,
} from "../../../common/helperContainer";
import {
  setStorage,
  clearAllStorages,
  clearStorage,
  getStorage,
} from "../../../common/storageHelper";
import MfaPopup from "./helpers/mfaPopup";
import EmailAuth from "./helpers/emailAuth";
import SMSAuth from "./helpers/smsAuth";
import AddPhone from "./helpers/addPhone";
import Bowser from "bowser";
import { v4 as uuidv4 } from "uuid";
import { googleReSiteKey } from "../../../common/apiURLs";
import { toastMessage } from "../../../common/toastMessage";
import {
  globalSaveAnalytics,
  resetDataTableValues,
  ConstructRecaptcha,
} from "../../../common/helperMethods";
import {
  OTPSentSuccessText,
  OTPErrorOccurredText,
  OTPExpiredText,
  InvalidOTPText,
  RegisteredLandlineText,
} from "../../../common/messageContainer";
import { checkAllCSRF } from "../../../common/csrfService";

const initialValues = {
  email: "",
  password: "",
};

const bowserDetails = {
  ip: "",
  platform: "",
  os: "",
  osVersion: "",
  browser: "",
  browserVersion: "",
  rememberMe: "",
  browserId: "",
};

function Login(props) {
  useEffect(() => {
    // clearDefRoute();
    getBrowserDetails();
    // Add reCaptcha
    ConstructRecaptcha();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let emailId;
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [invalidCrdError, setInvalidCrdError] = useState(false);
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showMfaPopup, setShowMfaPopup] = useState(false);
  const [showEmailAuth, setShowEmailAuth] = useState(false);
  const [showSMSAuth, setShowSMSAuth] = useState(false);
  const [showAddPhone, setAddPhone] = useState(false);
  const [acctLockError, setAcctLockError] = useState(false);
  const [isNewMobNumAdded, setIsNewMobNumAdded] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [notificationReq, setNotificationReq] = useState(false);
  const [failedLoginAcctLockEr, setFailedLoginAcctLockEr] = useState("");
  const [failedOTPAcctLockEr, setFailedOTPAcctLockEr] = useState("");
  const [isGoogleCapError, setIsGoogleCapError] = useState(false);

  const getBrowserDetails = async () => {
    let brId = getStorage(brwId);
    if (!brId) {
      brId = uuidv4();
      setStorage(brwId, brId);
    }
    bowserDetails.browserId = brId;
    const details = Bowser.parse(window.navigator.userAgent);
    bowserDetails.browser = details?.browser?.name;
    bowserDetails.browserVersion = details?.browser?.version;
    bowserDetails.os = details?.os?.name;
    bowserDetails.osVersion = details?.os?.versionName;
    bowserDetails.platform = details?.platform?.type;
  };

  const emailMfaType = () => {
    setShowMfaPopup(false);
    setShowEmailAuth(true);
    sendMfaOtpEmail();
  };

  const sendMfaOtpEmail = async () => {
    return await sendMFA(userName, bowserDetails.browserId, enumMFATypes.Email);
  };

  const smsMfaTypeFromTempMobile = () => {
    setShowMfaPopup(false);
    setShowSMSAuth(true);
  };

  const smsMfaType = () => {
    setShowMfaPopup(false);
    setShowSMSAuth(true);
    sendMfaOtpSMS();
  };

  const sendMfaOtpSMS = async () => {
    if (userName && bowserDetails?.browserId) {
      return await sendMFA(
        userName,
        bowserDetails.browserId,
        isNewMobNumAdded === true
          ? enumMFATypes.TempMobileNumber
          : enumMFATypes.SMS
      );
    }
  };

  const resendOTP = async (mfaType) => {
    if (mfaType === enumMFATypes.Email) {
      const res = await sendMfaOtpEmail();
      if (res?.status === 200 || res?.status === 201) {
        setIsLoader(false);
        toastMessage(enumMessageType.Success, OTPSentSuccessText);
      } else {
        toastMessage(enumMessageType.Error, OTPErrorOccurredText);
      }
    }
    if (mfaType === enumMFATypes.SMS) {
      const res = await sendMfaOtpSMS();
      if (res?.status === 200 || res?.status === 201) {
        setIsLoader(false);
        toastMessage(enumMessageType.Success, OTPSentSuccessText);
      } else {
        toastMessage(enumMessageType.Error, OTPErrorOccurredText);
      }
    }
    setIsLoader(false);
  };

  const googleMfaType = () => {
    alert("Selected type as Google Authenticator");
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid format")
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .matches(newEmailRegExp, "Invalid format")
      .required("Enter Email Address"),
    password: Yup.string()
      .min(3, "Minimum 3 characters")
      .max(20, "Maximum 20 characters")
      .required("Enter Password"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const handleClear = () => {
    setIsGoogleCapError(false);
    setInvalidCrdError(false);
    setAcctLockError(false);
    setFailedLoginAcctLockEr("");
    setFailedOTPAcctLockEr("");
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      clearAllStorages();
      enableLoading();
      emailId = values.email;
      setUserName(values.email);
      setPassword(values.password);
      handleClear();
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(googleReSiteKey, { action: "submit" })
          .then((token) => {
            login(
              values.email,
              values.password,
              bowserDetails.browserId,
              bowserDetails.platform,
              bowserDetails.os,
              bowserDetails.osVersion,
              bowserDetails.browser,
              bowserDetails.browserVersion,
              false,
              1,
              token
            )
              .then(({ data }) => {
                if (data && data.key === invalidCredentialId) {
                  saveAnalytics("Invalid Credentials");
                  setInvalidCrdError(true);
                  setAcctLockError(false);
                  setIsGoogleCapError(false);
                } else if (data && data.key === invalidMFAId) {
                  setPhoneNumber(data.value);
                  setShowMfaPopup(true);
                } else if (data && data.key === accountLockedId) {
                  saveAnalytics("Account Locked");
                  setAcctLockError(true);
                  setInvalidCrdError(false);
                  setIsGoogleCapError(false);
                  setFailedOTPAcctLockEr("");
                  setFailedLoginAcctLockEr(
                    "Too many failed login attempts. Account locked temporarily for 15 minutes."
                  );
                } else if (data && data.key === threeFailedOTPAttemptsId) {
                  saveAnalytics("3 failed OTP attempts. Account Locked.");
                  setAcctLockError(true);
                  setInvalidCrdError(false);
                  setIsGoogleCapError(false);
                  setShowMfaPopup(false);
                  setShowEmailAuth(false);
                  setShowSMSAuth(false);
                  setAddPhone(false);
                  setFailedLoginAcctLockEr("");
                  setFailedOTPAcctLockEr(
                    "Too many failed OTP attempts. Account locked temporarily for 15 minutes."
                  );
                } else if (data && data.key === googleRecaptchaId) {
                  saveAnalytics("Failed google recaptcha");
                  setAcctLockError(false);
                  setInvalidCrdError(false);
                  setIsGoogleCapError(true);
                } else if (data && data.access_token.token) {
                  resetDataTableValues(
                    enumDataTableTabs.AllApps,
                    "",
                    defaultPageNumber,
                    defaultSortColumn,
                    defaultSortOrder
                  );
                  clearStorage(dwret);
                  clearStorage(dwat);
                  setStorage(dwret, data.refresh_token);
                  setStorage(dwat, data.access_token.token);
                  props.login(data.access_token.token);
                  checkAllCSRF();
                } else {
                  saveAnalytics("Invalid Credentials");
                  setInvalidCrdError(true);
                  setAcctLockError(false);
                }
                disableLoading();
              })
              .catch(() => {
                disableLoading();
                // setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_LOGIN",
                  })
                );
              });
          });
      });
    },
  });

  const verifyMFA = (otp, rememberMe, queryUrl) => {
    enableLoading();
    verifyMfaOtp(
      userName,
      password,
      bowserDetails.browserId,
      otp,
      rememberMe,
      queryUrl
    ).then(({ data }) => {
      if (data && data.key === invalidMFAOTPId) {
        saveAnalytics("Invalid OTP");
        toastMessage(enumMessageType.Error, InvalidOTPText);
      } else if (data && data.key === oTPExpiredId) {
        saveAnalytics("OTP Expired");
        toastMessage(enumMessageType.Error, OTPExpiredText);
      } else if (data && data.key === noMobileNumberId) {
        setShowEmailAuth(false);
        setAddPhone(true);
      } else if (data && data.key === threeFailedOTPAttemptsId) {
        saveAnalytics("3 failed OTP attempts. Account Locked.");
        setAcctLockError(true);
        setInvalidCrdError(false);
        setShowMfaPopup(false);
        setShowEmailAuth(false);
        setShowSMSAuth(false);
        setAddPhone(false);
        setFailedLoginAcctLockEr("");
        setFailedOTPAcctLockEr(
          "Too many failed OTP attempts. Account locked temporarily for 15 minutes."
        );
      } else if (data && data.access_token.token) {
        resetDataTableValues(
          enumDataTableTabs.AllApps,
          "",
          defaultPageNumber,
          defaultSortColumn,
          defaultSortOrder
        );
        clearStorage(dwret);
        clearStorage(dwat);
        setStorage(dwret, data.refresh_token);
        setStorage(dwat, data.access_token.token);
        props.login(data.access_token.token);
        checkAllCSRF();
      } else {
        saveAnalytics("Invalid Credentials");
        setInvalidCrdError(true);
        setAcctLockError(false);
      }
      disableLoading();
    });
  };

  const saveTempMob = async (mobNum, notifyReq, bothOptions) => {
    const res = await saveTempMobile(
      mobNum,
      userName,
      bowserDetails.browserId,
      notifyReq,
      password,
      bothOptions
    );
    if (res && (res?.status === 200 || res?.status === 201)) {
      debugger;
      if (res && res?.data.key === registeredAsALandLineId) {
        saveAnalytics("Invalid mobile format");
        toastMessage(enumMessageType.Error, RegisteredLandlineText);
        setIsLoader(false);
      } else {
        if (notifyReq === true) {
          if (res?.data.access_token.token) {
            setAddPhone(false);
            resetDataTableValues(
              enumDataTableTabs.AllApps,
              "",
              defaultPageNumber,
              defaultSortColumn,
              defaultSortOrder
            );
            clearStorage(dwret);
            clearStorage(dwat);
            setStorage(dwret, res.data.refresh_token);
            setStorage(dwat, res.data.access_token.token);
            setIsLoader(false);
            props.login(res.data.access_token.token);
            checkAllCSRF();
          } else {
            saveAnalytics("Invalid Credentials");
            setInvalidCrdError(true);
            setAcctLockError(false);
            setIsLoader(false);
          }
        } else {
          setPhoneNumber(mobNum);
          setIsNewMobNumAdded(true);
          setAddPhone(false);
          smsMfaTypeFromTempMobile();
          setIsLoader(false);
        }
      }
    } else {
      setIsLoader(false);
      setLoading(false);
    }
  };

  const cancelTempMob = async (mobNum, notifyReq) => {
    setShowMfaPopup(false);
    setAddPhone(false);
    setShowEmailAuth(false);
    setShowSMSAuth(false);
    const res = await cancelTempMobile(
      mobNum,
      userName,
      bowserDetails.browserId,
      notifyReq,
      password
    );
    if (res && (res?.status === 200 || res?.status === 201)) {
      if (notifyReq === true) {
        if (res?.data.access_token.token) {
          resetDataTableValues(
            enumDataTableTabs.AllApps,
            "",
            defaultPageNumber,
            defaultSortColumn,
            defaultSortOrder
          );
          clearStorage(dwret);
          clearStorage(dwat);
          setStorage(dwret, res.data.refresh_token);
          setStorage(dwat, res.data.access_token.token);
          setIsLoader(false);
          props.login(res.data.access_token.token);
          checkAllCSRF();
        } else {
          saveAnalytics("Invalid Credentials");
          setInvalidCrdError(true);
          setAcctLockError(false);
          setIsLoader(false);
        }
      } else {
        setPhoneNumber(mobNum);
        setIsNewMobNumAdded(true);
        smsMfaTypeFromTempMobile();
        setIsLoader(false);
      }
    } else {
      setIsLoader(false);
      setLoading(false);
    }
  };

  const saveAnalytics = async (errMsg) => {
    await globalSaveAnalytics(
      "",
      "",
      "/auth/login",
      "0",
      "",
      "",
      errMsg,
      emailId ? emailId : userName
    );
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-6 mt-12 mb-lg-20">
        {/* <img alt="Logo" src={toAbsoluteUrl("/media/images/vclogo.png")} /> */}
        <img
          src={toAbsoluteUrl("/media/svg/vc-blue-and-grey.svg")}
          alt="Logo"
          style={{ height: "55px" }}
        />
        <div className="mb-8"></div>
        <h3 className="font-size-h2">Sign In</h3>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {invalidCrdError === true ? (
          <div className="row loginEmailError mr-0 ml-0">
            <div
              className="col-1 pl-0 pr-0"
              style={{ marginTop: "2px", marginBottom: "-2px" }}
            >
              <i className="fas fa-ban banIcon"></i>
            </div>
            <div className="col-11 pr-0 pl-0">
              <span>
                {"Invalid Credentials. Please try again or reset using "}
                <a href="/auth/forgotpassword" style={{ color: "#7e8299" }}>
                  <u>Forgot Password</u>
                </a>
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {acctLockError === true ? (
          <div className="row loginEmailError mr-0 ml-0">
            <div
              className="col-1 pl-0 pr-0"
              style={{ marginTop: "2px", marginBottom: "-2px" }}
            >
              <i className="fas fa-ban banIcon"></i>
            </div>
            <div className="col-11 pr-0 pl-0">
              <span>
                {failedLoginAcctLockEr !== ""
                  ? failedLoginAcctLockEr
                  : failedOTPAcctLockEr}
                <br></br>
                {"Unable to log in? Try "}
                <a href="/auth/forgotpassword" style={{ color: "#7e8299" }}>
                  <u>resetting your password</u>
                </a>
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {isGoogleCapError === true ? (
          <div className="row loginEmailError mr-0 ml-0">
            <div
              className="col-1 pl-0 pr-0"
              style={{ marginTop: "2px", marginBottom: "-2px" }}
            >
              <i className="fas fa-ban banIcon"></i>
            </div>
            <div className="col-11 pr-0 pl-0">
              <span>{"Failed google recaptcha."}</span>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="form-group fv-plugins-icon-container">
          <input
            autoComplete="off"
            placeholder="Email Address"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            onKeyDown={handleClear}
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="marginBottom0 form-group fv-plugins-icon-container">
          <input
            autoComplete="off"
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            onKeyDown={handleClear}
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="my-3 mr-2 text-right">
          <Link
            to="/auth/forgotpassword"
            className="fs-14"
            style={{ fontWeight: 600, color: "#187DE4" }}
          >
            Forgot Password
          </Link>
        </div>

        <div className="form-group text-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            // disabled={formik.isSubmitting}
            className={`btn btn-primary px-9 py-4 my-3`}
            style={{ borderRadius: "60px" }}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
        <div className="form-group text-center" style={{ fontSize: "11px" }}>
          This site is protected by reCAPTCHA and the Google{" "}
          <a target="blank" href="https://policies.google.com/privacy">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a target="blank" href="https://policies.google.com/terms">
            Terms of Service
          </a>{" "}
          apply.
        </div>
      </form>
      <MfaPopup
        showMfaPopup={showMfaPopup}
        setShowMfaPopup={setShowMfaPopup}
        emailMfaType={emailMfaType}
        smsMfaType={smsMfaType}
        googleMfaType={googleMfaType}
        userName={userName}
        phoneNumber={phoneNumber}
      ></MfaPopup>
      <EmailAuth
        showEmailAuth={showEmailAuth}
        setShowEmailAuth={setShowEmailAuth}
        setShowMfaPopup={setShowMfaPopup}
        verifyMFA={verifyMFA}
        userName={userName}
        resendOTP={resendOTP}
        isLoader={isLoader}
        setIsLoader={setIsLoader}
      ></EmailAuth>
      <SMSAuth
        showSMSAuth={showSMSAuth}
        setShowSMSAuth={setShowSMSAuth}
        setShowMfaPopup={setShowMfaPopup}
        verifyMFA={verifyMFA}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        resendOTP={resendOTP}
        isNewMobNumAdded={isNewMobNumAdded}
        setAddPhone={setAddPhone}
        isLoader={isLoader}
        setIsLoader={setIsLoader}
      ></SMSAuth>
      <AddPhone
        showAddPhone={showAddPhone}
        setAddPhone={setAddPhone}
        setShowMfaPopup={setShowMfaPopup}
        smsMfaTypeFromTempMobile={smsMfaTypeFromTempMobile}
        phoneNumber={phoneNumber}
        saveTempMob={saveTempMob}
        cancelTempMob={cancelTempMob}
        isLoader={isLoader}
        setIsLoader={setIsLoader}
        notificationReq={notificationReq}
        setNotificationReq={setNotificationReq}
      ></AddPhone>
    </div>
  );
}
export default injectIntl(connect(null, auth.actions)(Login));
