import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_veroscredit/layout";
import * as auth from "../_redux/authRedux";
import { clearDefRoute } from "../../../common/helperMethods";
import { clearAllStorages } from "../../../common/storageHelper";

class Logout extends Component {
  componentDidMount() {
    clearDefRoute();
    clearAllStorages();
    //Above methods came from AsideMenuList page due to App Entry page leaving issue clearing storage.
    this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to="/auth/login" />
    );
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
