/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  return (
    <div>
      <img
        style={{ marginLeft: "-10px" }}
        className="mt-7"
        alt="logo"
        src={toAbsoluteUrl("/media/images/Worxs.png")}
      />
    </div>
  );
}
