import React from "react";
import { idealTimeout } from "../helperContainer";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

const IdleTimerContainer = () => {
  const history = useHistory();
  const onIdle = () => {
    // setIsIdealBrowserTime(true);
    history.push("/logout");
  };

  useIdleTimer({
    timeout: idealTimeout,
    onIdle: onIdle,
  });

  return <></>;
};

export default IdleTimerContainer;
