import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { jq } from "../../../../common/dataTable/imports";
import {
  customErrorMsg,
  enumMFATypes,
} from "../../../../common/helperContainer";
import NumberFormat from "react-number-format";
import CircleSpinner from "../../../../common/loader/circleSpinner";

const EmailAuth = React.memo((props: any) => {
  const [mfaOtp, setMfaOtp] = useState("");
  const [mfaOtpError, setMfaOtpError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleMfaOtp = (e: any) => {
    setMfaOtp(e.target.value);
    setMfaOtpError("");
  };

  const handleRememberMe = (e: any) => {
    setRememberMe(e.checked);
  };

  useEffect(() => {
    setMfaOtp("");
    setRememberMe(false);
    setMfaOtpError("");
    if (props.showEmailAuth === true) {
      checkEmailPopup();
    }
  }, [props.showEmailAuth]);

  const checkEmailPopup = () => {
    let innerHeight = window.innerHeight;
    let popupHeight = document.getElementById("modalEmailPopup");
    let calHeight = popupHeight?.clientHeight ? popupHeight?.clientHeight : 0;
    let diffHeight = innerHeight - calHeight;

    if (diffHeight <= 0) {
      jq("#modalEmailPopup").css("margin-top", "100px");
    } else {
      jq("#modalEmailPopup").css("margin-top", `${diffHeight / 2}px`);
    }
  };

  const handleBack = () => {
    props.setShowEmailAuth(false);
    props.setShowMfaPopup(true);
  };

  const resendCode = () => {
    props.setIsLoader(true);
    props.resendOTP(enumMFATypes.Email);
  };

  const verifyCode = () => {
    if (mfaOtp === "" || mfaOtp === null || mfaOtp === undefined) {
      setMfaOtpError(customErrorMsg);
    } else {
      if (mfaOtp.length !== 6) {
        setMfaOtpError("Length should be 6");
      } else {
        const queryUrl = `/umi/verifymfa`;
        props.verifyMFA(mfaOtp, rememberMe, queryUrl);
      }
    }
  };

  return (
    <div>
      {/* MFA Email popup */}
      <Modal
        backdrop="static"
        show={props.showEmailAuth}
        onHide={() => {
          props.setShowEmailAuth(false);
        }}
        id="modalEmailPopup"
        className="popupDefaultRightPadding"
      >
        <div
          className={`${
            props.isLoader === true ? "loaderGrey" : "loaderDispaly"
          }`}
        >
          <CircleSpinner />
        </div>
        <Modal.Header>
          <div>
            <h5
              className="text-dark fw-bold d-block fs-3"
              style={{ fontWeight: 400, marginBottom: "0px" }}
            >
              Enter Email Verification Code
            </h5>
          </div>
          <div className="commentPopupClose">
            <button
              type="button"
              className="pr-0 popupCloseNew"
              onClick={() => {
                props.setShowEmailAuth(false);
              }}
            >
              <i className="la la-remove" style={{ fontSize: "large" }}></i>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="p-model-body">
          <div className="col-md-12 col-lg-12 col-12">
            <div className="col-md-12 col-lg-12 col-12 mt-4 mb-6">
              <span className="text-muted fs-6">
                Please enter the 6 digit verification just sent to <br></br>
                {`${props.userName.substr(0, 3)}xxxx${props.userName.substr(
                  props.userName.search("@")
                )}`}
              </span>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-12">
            <div className="col-md-12 col-lg-12 col-12 mt-4 mb-6">
              <NumberFormat
                placeholder="Enter authentication code"
                type="text"
                autoComplete="off"
                name="mfaOtp"
                maxLength={6}
                className="form-control form-control-solid h-auto py-5 px-6"
                value={mfaOtp}
                onChange={handleMfaOtp}
              />
              <span className="errorMsg">
                {mfaOtpError ? <div>{mfaOtpError}</div> : null}
              </span>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-12">
            <div className="col-md-12 col-lg-12 col-12 mt-4 mb-2">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => handleRememberMe(e.target)}
                className="filled-in"
                name="rememberMe"
              />
              <span className="text-muted fs-6 ml-3">
                Remember me on this device
              </span>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-12">
            <div className="col-md-12 col-lg-12 col-12 mb-6 ml-7">
              <span className="text-muted fs-6">
                Didn't get the code?{"  "}
                <div
                  className="cursorPointer"
                  onClick={resendCode}
                  style={{ color: "#00d0f0" }}
                >
                  Resend
                </div>
              </span>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-12">
            <div className="col-md-12 col-lg-12 col-12 mb-4 text-center">
              <button
                type="button"
                style={{ width: "30%" }}
                className="btn btn-secondary m-btn m-btn--custom m-btn--icon"
                onClick={handleBack}
              >
                Back
              </button>
              <button
                type="button"
                style={{ width: "30%" }}
                className="btn btn-focus m-btn m-btn--custom m-btn--icon ml-4"
                onClick={verifyCode}
              >
                Verify
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
});
export default EmailAuth;
