import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { IUpdatePassword } from "../authModels";
import { pwdAllowedSplChar, pwdRegExp } from "../../../common/helperContainer";
import { toAbsoluteUrl } from "../../../../_veroscredit/_helpers";
import { resetForgottenPassword } from "../_redux/authCrud";
import { toastMessage } from "../../../../app/common/toastMessage";
import { enumMessageType } from "../../../../app/common/helperContainer";
import { useHistory } from "react-router-dom";
import { clearDefRoute } from "../../../common/helperMethods";
import { UpdatePasswordSuccessText } from "../../../common/messageContainer";
import CircleSpinner from "../../../common/loader/circleSpinner";

const UpdatePassword = (props: any): JSX.Element => {
  const history = useHistory();
  const [isLoader, setIsLoader] = useState(true);
  const intValues: IUpdatePassword = {
    confirmPassword: "",
    password: "",
  };

  useEffect(() => {
    clearDefRoute();
    setIsLoader(false);
  }, []);

  const handleOnSubmit = (values: any) => {
    updatePwd(values);
  };

  const updatePwd = async (values: any) => {
    setIsLoader(true);
    const response: any = await resetForgottenPassword(
      values.password,
      props.match.params.token
    );
    if (response?.status === 200) {
      toastMessage(enumMessageType.Success, UpdatePasswordSuccessText);
      setIsLoader(false);
      history.push("/logout");
    } else {
      setIsLoader(false);
    }
    clearDefRoute();
  };

  const handleClear = () => {
    clearDefRoute();
    history.push("/auth");
  };

  return (
    <div>
      <div className={`${isLoader === true ? "loaderGrey" : "loaderDispaly"}`}>
        <CircleSpinner />
      </div>
      <Formik
        initialValues={intValues}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .required("Enter New Password")
            .max(20, "Password must be max 20 characters")
            .matches(
              pwdRegExp,
              `Password must be min 8 characters, 1 Uppercase, 1 Lowercase, 1 Number, 1 Special Character (allowed special characters ${pwdAllowedSplChar})`
            ),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), ""], "Password must match")
            .max(20, "Password must be max 20 characters")
            .required("Enter Verify Password"),
        })}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          handleChange,
          errors,
          handleBlur,
          resetForm,
        }) => (
          <>
            <div className="card-custom">
              <div className="text-center">
                <img
                  src={toAbsoluteUrl("/media/svg/vc-blue-and-grey.svg")}
                  alt="Logo"
                  style={{ height: "55px" }}
                />
              </div>
              <div className="form">
                <div className="tab-content">
                  <Form
                    noValidate
                    onSubmit={handleSubmit}
                    style={{ padding: "70px" }}
                  >
                    <div className="form-group row">
                      <div className="col-lg-12 col-xl-12 text-center mb-5">
                        <h3 className="font-size-h2">Reset Password</h3>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-12 col-xl-12">
                        <Form.Control
                          type="password"
                          placeholder="New Password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                        ></Form.Control>
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ maxWidth: "200px" }}
                        >
                          <>{errors.password}</>
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-12 col-xl-12">
                        <Form.Control
                          type="password"
                          placeholder="Verify Password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          isInvalid={!!errors.confirmPassword}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          <>{errors.confirmPassword}</>
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-12 text-center mt-10">
                        <button
                          type="submit"
                          className={`btn btn-primary btn-saveChanges px-8 py-3 my-3 mx-3`}
                        >
                          Submit
                        </button>
                        <span onClick={handleClear} className="cursorPointer">
                          <button
                            type="button"
                            id="kt_login_forgot_cancel"
                            className="btn btn-light-primary px-8 py-3 my-3 mx-3"
                          >
                            Cancel
                          </button>
                        </span>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default UpdatePassword;
