import { apiGateway } from "../../common/apiURLs";
import { get, post, put } from "../../common/commonApi";
import { loanCookie } from "../../common/csrfService";
import { enumContentTypes } from "../../common/helperContainer";

export const saveApplication = async (retData: any) => {
  const queryUrl = `/api/loanapp/RetailAppLoan`;
  return await post(
    apiGateway,
    queryUrl,
    retData,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const updateApplication = async (retData: any, appId: number) => {
  const queryUrl = `/api/loanapp/RetailAppLoan/${appId}`;
  return await put(
    apiGateway,
    queryUrl,
    retData,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const updateApplicationNoReturn = async (
  retData: any,
  appId: number
) => {
  const queryUrl = `/api/loanapp/RetailAppLoan/${appId}`;
  return await put(
    apiGateway,
    queryUrl,
    retData,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const saveVehicle = async (vehicleData: any, appId: number) => {
  const queryUrl = `/api/loanapp/RetailAppLoan/${appId}/vehicle`;
  return await put(
    apiGateway,
    queryUrl,
    vehicleData,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const saveVehicleNoReturn = async (vehicleData: any, appId: number) => {
  const queryUrl = `/api/loanapp/RetailAppLoan/${appId}/vehicle`;
  return await put(
    apiGateway,
    queryUrl,
    vehicleData,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const saveDealStructure = async (dealData: any, appId: number) => {
  const queryUrl = `/api/loanapp/RetailAppLoan/${appId}/dealstructure`;
  return await put(
    apiGateway,
    queryUrl,
    dealData,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const saveDealStructureNoReturn = async (
  dealData: any,
  appId: number
) => {
  const queryUrl = `/api/loanapp/RetailAppLoan/${appId}/dealstructure`;
  return await put(
    apiGateway,
    queryUrl,
    dealData,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const submitLoanNoReturn = async (appId: number) => {
  const queryUrl = `/api/loanapp/RetailAppLoan/${appId}/submit`;
  return await put(
    apiGateway,
    queryUrl,
    "",
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const changesStatus = async (appId: number, status: number) => {
  var bodyFormData = new FormData();
  bodyFormData.append("Status", status.toString());
  const queryUrl = `/api/loanapp/RetailAppLoan/${appId}/status`;
  return await put(
    apiGateway,
    queryUrl,
    bodyFormData,
    enumContentTypes.formencoded,
    false,
    loanCookie
  );
};

export const getRetailerLoanOnPageLoad = async (dealerId: string) => {
  const queryUrl = `/api/loanapp/RetailAppLoan/${dealerId}`;
  return await get(apiGateway, queryUrl, false, loanCookie);
};
