import React from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_veroscredit/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import TagManager from "react-gtm-module";
import {
  googleTagMgrId,
  reScOutVal,
  reScOut,
  defRt,
  excludeRt,
  contDlNoOfPg,
  contDlDt,
} from "../app/common/helperContainer";
import { globalSaveAnalytics } from "../app/common/helperMethods";
import packageJson from "../../package.json";
import {
  getStorage,
  clearStorage,
  setStorage,
} from "../app/common/storageHelper";
import { rehashPath } from "../app/common/helperContainer";
import { removeStorage } from "../_veroscredit/_helpers";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const rtPass = getStorage(defRt);
  const rfchk = getStorage("rfchk");
  const currentVer = packageJson.version;
  // Check if rfchk is not equal to current version or if it's not set
  if (rfchk !== currentVer) {
    // Store the current version in local storage
    setStorage("rfchk", currentVer);
    // Reload the page
    window.location.reload();
  }

  React.useEffect(() => {
    checkDefRt();
    //checkNewRealese();
    googleTagManager();
    analyticsSave();
    const element = document.getElementById("fundingUpdate");
    element && element.classList.remove("fundingUpdateIcon");
    clearContractFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const googleTagManager = () => {
    const dealerId =
      user && user[1].assDealer.length > 0
        ? user[1].assDealer.filter((o) => o.isDefault === true)[0].dealerId
        : "000";
    const tagManagerArgs = {
      gtmId: googleTagMgrId,
      dataLayer: {
        userId: dealerId,
      },
    };
    TagManager.initialize(tagManagerArgs);
  };

  const analyticsSave = async () => {
    if (!isAuthorized) {
      await globalSaveAnalytics("", "", "/auth/login", "0", "", "", "", "");
    }

    const val = getStorage(reScOutVal);
    //const isVal = getStorage(reScOut);
    //if (val !== null && isVal && isVal === "1") {
    if (val !== null && !location.pathname.includes(`rehash/${val}`)) {
      const role = user && user[1].role ? user[1].role : "";
      const userId = user && user[1].userId ? user[1].userId : "";
      const pageUrl = `${rehashPath}${val}`;
      const dealerId =
        user && user[1].assDealer.length > 0
          ? user[1].assDealer.filter((o) => o.isDefault === true)[0].dealerId
          : "";
      const dealerName =
        user && user[1].assDealer.length > 0
          ? user[1].assDealer.filter((o) => o.isDefault === true)[0].name
          : "";

      clearStorage(reScOut);
      clearStorage(reScOutVal);
      await globalSaveAnalytics(
        userId,
        role,
        pageUrl,
        val,
        dealerId,
        dealerName,
        "Out",
        user[1].email
      );
    }
  };

  // const checkNewRealese = async () => {
  //   const response = await fetch("/meta.json");
  //   const result = await response.json();
  //   const latestVersionDate = result.buildDate;
  //   const currentVersionDate = packageJson.buildDate;

  //   if (latestVersionDate && currentVersionDate) {
  //     const shouldForceRefresh = buildDateGreaterThan(
  //       latestVersionDate,
  //       currentVersionDate
  //     );
  //     if (shouldForceRefresh === true) {
  //       refreshCacheAndReload();
  //     }
  //   }
  // };

  // const buildDateGreaterThan = (latestDate, currentDate) => {
  //   const momLatestDateTime = moment(latestDate);
  //   const momCurrentDateTime = moment(currentDate);
  //   if (
  //     momLatestDateTime &&
  //     momCurrentDateTime &&
  //     momLatestDateTime.isAfter(momCurrentDateTime)
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // const refreshCacheAndReload = () => {
  //   // browser reload
  //   window.location.reload();
  // };

  const checkDefRt = () => {
    if (
      !excludeRt.includes(location.pathname) &&
      !location.pathname.includes("auth")
    ) {
      setStorage(defRt, location.pathname);
    }
  };

  const clearContractFile = () => {
    const savedFile = getStorage(contDlDt);
    const savedPages = getStorage(contDlNoOfPg);
    if (
      savedFile &&
      savedPages &&
      !location.pathname.includes("previewConfirmContract")
    ) {
      removeStorage(contDlDt);
      removeStorage(contDlNoOfPg);
    }
  };

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to={rtPass !== null ? rtPass : "/"} />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
