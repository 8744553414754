import React from "react";
import { toAbsoluteUrl } from "../../../_veroscredit/_helpers";

const CircleSpinner = () => {
  return (
    <img
      src={toAbsoluteUrl("/media/svg/Circle_Spinner.svg")}
      alt="Circle Spinner"
      style={{ height: "80px" }}
    />
  );
};

export default CircleSpinner;
