/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_veroscredit/_helpers";
import { ContentRoute } from "../../../../_veroscredit/layout";
import Login from "./Login";
import ForgotPassword from "./forgotPassword";
import UpdatePassword from "./updatePassword";
import NewUserPassword from "./newUserPassword";
import "../../../../_veroscredit/_assets/sass/pages/login/classic/login-1.scss";
import ClearDefaultRoute from "../../../common/clearDefaultRoute/clearDefaultRoute";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-10 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  exact={true}
                  path="/auth/forgotpassword"
                  component={ForgotPassword}
                />
                <ContentRoute
                  exact={true}
                  path="/ResetForgottenPassword/:token"
                  component={UpdatePassword}
                />
                <ContentRoute
                  exact={true}
                  path="/NewUserConfirmEmail/:token"
                  component={NewUserPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}

          {/*begin::Aside*/}
          <div
            className="loginMaxWidth login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/images/bg-5.jpg"
              )})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <div className="loginRightImage flex-column-auto mb-6 mt-12 mt-lg-40">
                {/* <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/media/images/logo_default_dark.png")}
                /> */}
                <div style={{ display: "flex" }}>
                  <img
                    src={toAbsoluteUrl("/media/svg/vc-white-no-bg.svg")}
                    alt="Logo"
                    className="max-h-45px"
                  />
                </div>
                <h3
                  className="mb-5 mt-5 font-size-max text-white"
                  style={{ fontFamily: "HandelGothic" }}
                >
                  DealLane
                  {/* <img
                    alt="Dealerworxs Logo"
                    src={toAbsoluteUrl("/media/images/WorxsLogin.png")}
                  /> */}
                </h3>
                <p className="font-weight-lighter text-white loginPara">
                  Veros Credit is a leading provider of auto financing
                  solutions. We specialize in the acquisition and servicing of
                  motor vehicle retail installment contracts through our vast
                  network of franchise and independent automobile dealers. Our
                  20+ years of subprime and near prime auto financing
                  experience, combined with our commitment to strong personal
                  relationships with our dealers and customers, enables us to
                  deliver quality service and a robust financing program.
                </p>
              </div>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center"></div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10"></div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}
        </div>
        {/*end::Login*/}
        <ClearDefaultRoute />
      </div>
    </>
  );
}
