import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_veroscredit/layout";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useSelector } from "react-redux";
import { DefaultMenuTabRoute } from "./common/helperMethods";

const BasePage = (props: any) => {
  const { user } = useSelector((state: any) => state.auth);
  const menuTabs = user[1].menuTabPermission;
  let defRoute = DefaultMenuTabRoute(menuTabs);

  const Admin = React.lazy(() => import("../app/modules/admin/adminRoutes"));
  const AddLoan = React.lazy(
    () => import("../app/modules/addLoan/addLoanRoutes")
  );
  const Dealer = React.lazy(() => import("../app/modules/dealer/dealerRoutes"));
  const Dashboard = React.lazy(
    () => import("../app/modules/dashboard/dashboardRoutes")
  );

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect
            exact
            from="/"
            to={defRoute}
          /> /* Redirect from root URL to AllApps. */
        }
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/admin" component={Admin} />
        <Route path="/addLoan" component={AddLoan} />
        <Route path="/dealer" component={Dealer} />
        <Route path="/error" component={ErrorsPage} />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};
export default BasePage;
