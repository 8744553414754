import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { enumMessageType } from "./helperContainer";

export const toastMessage = (
  messageType: number,
  message: string,
  time: number = 6000
) => {
  if (messageType === enumMessageType.Success) {
    toast.success(message, {
      position: "bottom-right",
      hideProgressBar: false,
      autoClose: time,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  } else if (messageType === enumMessageType.Error) {
    toast.error(message, {
      position: "bottom-right",
      hideProgressBar: false,
      autoClose: time,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  } else if (messageType === enumMessageType.Warning) {
    toast.warning(message, {
      position: "bottom-right",
      hideProgressBar: false,
      autoClose: time,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  } else {
    toast.info(message, {
      position: "bottom-right",
      hideProgressBar: false,
      autoClose: time,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  }
};
