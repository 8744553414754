import React, { useEffect } from "react";
import { checkAndUpdateToken } from "../helperMethods";
import { useSelector } from "react-redux";
import { refreshTime, rfTmDt } from "../helperContainer";
import { clearStorage, getStorage, setStorage } from "../storageHelper";

const RefreshTimer = React.memo((props: any) => {
  const { user } = useSelector((state: any) => state.auth);
  let timer: any;
  let calcTimer: any;

  useEffect(() => {
    if (user[1].userId) {
      initializeTokenValidationTimer();
    } else {
      clearInterval(timer);
      clearStorage(rfTmDt);
    }
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user[1].userId]);

  const validateToken = async () => {
    const response = await checkAndUpdateToken();
    if (response) {
      clearStorage(rfTmDt);
      clearInterval(timer);
      initializeTokenValidationTimer();
    }
  };

  const initializeTokenValidationTimer = () => {
    const storedTimestamp = getStorage(rfTmDt);
    if (storedTimestamp) {
      const storedDateTime = new Date(Number(storedTimestamp));
      const currentDateTime = new Date();
      const differenceInMilliseconds =
        currentDateTime.getTime() - storedDateTime.getTime();
      calcTimer =
        Number(refreshTime - differenceInMilliseconds) < 10000
          ? 10000
          : Number(refreshTime - differenceInMilliseconds);
    } else {
      calcTimer = refreshTime;
      const currentTimestamp = new Date().getTime();
      setStorage(rfTmDt, currentTimestamp.toString());
    }
    timer = setInterval(() => validateToken(), calcTimer);
  };

  return <div></div>;
});
export default RefreshTimer;
