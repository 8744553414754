import { getDropdown } from "../modules/admin/adminServices";

export let FeatureNotEnabledText = "This feature not enabled.";
export let DataSubmittedSuccessfullyText = "Data submitted successfully.";
export let ErrorOccurredText = "Error occurred.";
export let SessionExpiredText = "Your session has expired. Please relogin.";
export let SomethingWentWrongText = "Something went wrong, please try again.";
export let FileExtensionDoesntMatchText =
  "File extension does not match actual format.";
export let UploadInvalidFileTypeText =
  "Invalid file type. Only jpeg, jpg, png, bmp, gif, pdf, heic are accepted.";
export let FileSizeTooLargeText =
  "File size too large. Required file size less than 20MB.";
export let FileSizeTooSmallText =
  "File size too small. Required file size greater than 1KB.";
export let AcceptsOneFileText = "Accepts only 1 file at a time.";
export let ErrorInDealerCommitUploadText =
  "Error occurred in dealer commitment while uploading the document.";
export let ErrorInStipDocUploadText =
  "Error occurred in stip document uploader while uploading the document.";
export let UnableToCaptureFileNameText =
  "Unable to capture the file name from the upload controller.";
export let DealerInvalidFileTypeText =
  "Invalid file type. Only jpeg, jpg, png are accepted.";
export let NoDataFoundText = "No data found.";
export let EnterValidUserIdText = "Enter valid user id.";
export let EnterValidPageNoText = "Enter valid page number.";
export let ResetPassSuccessText =
  "If there is an account associate with that email address, we will email you a link to reset your password. Make sure to check your spam.";
export let InvalidEmailText = "Invalid email address.";
export let OTPSentSuccessText = "OTP sent successfully.";
export let OTPErrorOccurredText =
  "Error occurred, while sending OTP. Please try again.";
export let InvalidOTPText = "Invalid OTP.";
export let OTPExpiredText = "OTP expired.";
export let RegisteredLandlineText =
  "This is registered as a landline, Please provide a mobile number.";
export let CreatePasswordSuccessText = "Password created successfully.";
export let UpdatePasswordSuccessText = "Password updated successfully.";
export let UnableToAccessText = "Unable to access.";
export let LoanDetailsNotFoundText = "Loan details not found.";
export let SelectOneAULRatesText = "Select atleast 1 AUL rates.";
export let NoRehashFoundText = "No rehash found.";
export let NoAULRatesFoundText = "No AUL rates found.";
export let DealerAvatarFileSize =
  "File size too large. Required file size less than 2MB.";

export const getMessages = async () => {
  //ParentId of Toastr Notification Messages in Taxonomy DB under Lookups
  const parentId = 303;
  const result = await getDropdown(parentId);
  const data = result?.data;
  FeatureNotEnabledText =
    data.filter((x: any) => x.name === "FeatureNotEnabled").length > 0
      ? data.filter((x: any) => x.name === "FeatureNotEnabled")[0].description
      : FeatureNotEnabledText;
  DataSubmittedSuccessfullyText =
    data.filter((x: any) => x.name === "DataSubmittedSuccessfully").length > 0
      ? data.filter((x: any) => x.name === "DataSubmittedSuccessfully")[0]
          .description
      : DataSubmittedSuccessfullyText;
  ErrorOccurredText =
    data.filter((x: any) => x.name === "ErrorOccurred").length > 0
      ? data.filter((x: any) => x.name === "ErrorOccurred")[0].description
      : ErrorOccurredText;
  SessionExpiredText =
    data.filter((x: any) => x.name === "SessionExpired").length > 0
      ? data.filter((x: any) => x.name === "SessionExpired")[0].description
      : SessionExpiredText;
  SomethingWentWrongText =
    data.filter((x: any) => x.name === "SomethingWentWrong").length > 0
      ? data.filter((x: any) => x.name === "SomethingWentWrong")[0].description
      : SomethingWentWrongText;
  FileExtensionDoesntMatchText =
    data.filter((x: any) => x.name === "FileExtensionDoesntMatch").length > 0
      ? data.filter((x: any) => x.name === "FileExtensionDoesntMatch")[0]
          .description
      : FileExtensionDoesntMatchText;
  UploadInvalidFileTypeText =
    data.filter((x: any) => x.name === "UploadInvalidFileType").length > 0
      ? data.filter((x: any) => x.name === "UploadInvalidFileType")[0]
          .description
      : UploadInvalidFileTypeText;
  FileSizeTooLargeText =
    data.filter((x: any) => x.name === "FileSizeTooLarge").length > 0
      ? data.filter((x: any) => x.name === "FileSizeTooLarge")[0].description
      : FileSizeTooLargeText;
  FileSizeTooSmallText =
    data.filter((x: any) => x.name === "FileSizeTooSmall").length > 0
      ? data.filter((x: any) => x.name === "FileSizeTooSmall")[0].description
      : FileSizeTooSmallText;
  AcceptsOneFileText =
    data.filter((x: any) => x.name === "AcceptsOneFile").length > 0
      ? data.filter((x: any) => x.name === "AcceptsOneFile")[0].description
      : AcceptsOneFileText;
  ErrorInDealerCommitUploadText =
    data.filter((x: any) => x.name === "ErrorInDealerCommitUpload").length > 0
      ? data.filter((x: any) => x.name === "ErrorInDealerCommitUpload")[0]
          .description
      : ErrorInDealerCommitUploadText;
  ErrorInStipDocUploadText =
    data.filter((x: any) => x.name === "ErrorInStipDocUpload").length > 0
      ? data.filter((x: any) => x.name === "ErrorInStipDocUpload")[0]
          .description
      : ErrorInStipDocUploadText;
  UnableToCaptureFileNameText =
    data.filter((x: any) => x.name === "UnableToCaptureFileName").length > 0
      ? data.filter((x: any) => x.name === "UnableToCaptureFileName")[0]
          .description
      : UnableToCaptureFileNameText;
  DealerInvalidFileTypeText =
    data.filter((x: any) => x.name === "DealerInvalidFileType").length > 0
      ? data.filter((x: any) => x.name === "DealerInvalidFileType")[0]
          .description
      : DealerInvalidFileTypeText;
  NoDataFoundText =
    data.filter((x: any) => x.name === "NoDataFound").length > 0
      ? data.filter((x: any) => x.name === "NoDataFound")[0].description
      : NoDataFoundText;
  EnterValidUserIdText =
    data.filter((x: any) => x.name === "EnterValidUserId").length > 0
      ? data.filter((x: any) => x.name === "EnterValidUserId")[0].description
      : EnterValidUserIdText;
  EnterValidPageNoText =
    data.filter((x: any) => x.name === "EnterValidPageNo").length > 0
      ? data.filter((x: any) => x.name === "EnterValidPageNo")[0].description
      : EnterValidPageNoText;
  ResetPassSuccessText =
    data.filter((x: any) => x.name === "ResetPassSuccess").length > 0
      ? data.filter((x: any) => x.name === "ResetPassSuccess")[0].description
      : ResetPassSuccessText;
  InvalidEmailText =
    data.filter((x: any) => x.name === "InvalidEmail").length > 0
      ? data.filter((x: any) => x.name === "InvalidEmail")[0].description
      : InvalidEmailText;
  OTPSentSuccessText =
    data.filter((x: any) => x.name === "OTPSentSuccess").length > 0
      ? data.filter((x: any) => x.name === "OTPSentSuccess")[0].description
      : OTPSentSuccessText;
  OTPErrorOccurredText =
    data.filter((x: any) => x.name === "OTPErrorOccurred").length > 0
      ? data.filter((x: any) => x.name === "OTPErrorOccurred")[0].description
      : OTPErrorOccurredText;
  InvalidOTPText =
    data.filter((x: any) => x.name === "InvalidOTP").length > 0
      ? data.filter((x: any) => x.name === "InvalidOTP")[0].description
      : InvalidOTPText;
  OTPExpiredText =
    data.filter((x: any) => x.name === "OTPExpired").length > 0
      ? data.filter((x: any) => x.name === "OTPExpired")[0].description
      : OTPExpiredText;
  RegisteredLandlineText =
    data.filter((x: any) => x.name === "RegisteredLandline").length > 0
      ? data.filter((x: any) => x.name === "RegisteredLandline")[0].description
      : RegisteredLandlineText;
  CreatePasswordSuccessText =
    data.filter((x: any) => x.name === "CreatePasswordSuccess").length > 0
      ? data.filter((x: any) => x.name === "CreatePasswordSuccess")[0]
          .description
      : CreatePasswordSuccessText;
  UpdatePasswordSuccessText =
    data.filter((x: any) => x.name === "UpdatePasswordSuccess").length > 0
      ? data.filter((x: any) => x.name === "UpdatePasswordSuccess")[0]
          .description
      : UpdatePasswordSuccessText;
  UnableToAccessText =
    data.filter((x: any) => x.name === "UnableToAccess").length > 0
      ? data.filter((x: any) => x.name === "UnableToAccess")[0].description
      : UnableToAccessText;
  LoanDetailsNotFoundText =
    data.filter((x: any) => x.name === "LoanDetailsNotFound").length > 0
      ? data.filter((x: any) => x.name === "LoanDetailsNotFound")[0].description
      : LoanDetailsNotFoundText;
  SelectOneAULRatesText =
    data.filter((x: any) => x.name === "SelectOneAULRates").length > 0
      ? data.filter((x: any) => x.name === "SelectOneAULRates")[0].description
      : SelectOneAULRatesText;
  NoRehashFoundText =
    data.filter((x: any) => x.name === "NoRehashFound").length > 0
      ? data.filter((x: any) => x.name === "NoRehashFound")[0].description
      : NoRehashFoundText;
  NoAULRatesFoundText =
    data.filter((x: any) => x.name === "NoAULRatesFound").length > 0
      ? data.filter((x: any) => x.name === "NoAULRatesFound")[0].description
      : NoAULRatesFoundText;
  DealerAvatarFileSize =
    data.filter((x: any) => x.name === "DealerAvatarFileSize").length > 0
      ? data.filter((x: any) => x.name === "DealerAvatarFileSize")[0]
          .description
      : DealerAvatarFileSize;
};
