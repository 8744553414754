import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { jq } from "../../../../common/dataTable/imports";
import { formatUSMobileNumber } from "../../../../common/helperMethods";

const MfaPopup = React.memo((props: any) => {
  useEffect(() => {
    if (props.showMfaPopup === true) {
      checkMfaPopup();
    }
  }, [props.showMfaPopup]);

  const checkMfaPopup = () => {
    let innerHeight = window.innerHeight;
    let popupHeight = document.getElementById("modalPopupAuth");
    let calHeight = popupHeight?.clientHeight ? popupHeight?.clientHeight : 0;
    let diffHeight = innerHeight - calHeight;

    if (diffHeight <= 0) {
      jq("#modalPopupAuth").css("margin-top", "100px");
    } else {
      jq("#modalPopupAuth").css("margin-top", `${diffHeight / 2}px`);
    }
  };

  return (
    <div>
      {/* MFA popup */}
      <Modal
        backdrop="static"
        show={props.showMfaPopup}
        onHide={() => {
          props.setShowMfaPopup(false);
        }}
        id="modalPopupAuth"
        className="popupDefaultRightPadding"
      >
        <Modal.Header>
          <div>
            <h5
              className="text-dark fw-bold d-block fs-3"
              style={{ fontWeight: 400, marginBottom: "0px" }}
            >
              Choose An Authentication Method
            </h5>
          </div>
          <div className="commentPopupClose">
            <button
              type="button"
              className="pr-0 popupCloseNew"
              onClick={() => {
                props.setShowMfaPopup(false);
              }}
            >
              <i className="la la-remove" style={{ fontSize: "large" }}></i>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="p-model-body">
          <div className="col-md-12 col-lg-12 col-12">
            <div className="col-md-12 col-lg-12 col-12 mt-4 mb-6">
              <h6 className="text-muted fw-semibold fs-6">
                In addition to your username and password, you’ll have to enter
                a code to log into your account.
                {/* In addition to your username and password, you’ll have to enter
                a code (delivered via app or SMS or EMAIL) to log into your
                account. */}
              </h6>
            </div>
          </div>
          {/* <div className="col-md-12 col-lg-12 col-12">
            <div
              className="cursorPointer authMargin row authDashed"
              onClick={() => {
                props.googleMfaType();
              }}
            >
              <span>
                <i
                  className="authContAlign fas fa-cog"
                  title="Google Authenticator"
                ></i>
              </span>
              <span className="d-block fw-semibold text-start ml-4">
                <span className="text-dark fw-bold d-block fs-3">
                  Authenticator Apps
                </span>
                <span className="text-muted fw-semibold fs-6">
                  Get code from Google Authenticator
                </span>
              </span>
            </div>
          </div> */}
          <div className="col-md-12 col-lg-12 col-12">
            <div
              className="cursorPointer authMargin row authDashed"
              onClick={() => {
                props.emailMfaType();
              }}
            >
              <span>
                <i className="authContAlign fas fa-envelope" title="Email"></i>
              </span>
              <span className="d-block fw-semibold text-start ml-4">
                <span className="text-dark fw-bold d-block fs-3">
                  One-time password (EMAIL)
                </span>
                <span className="text-muted fw-semibold fs-6">
                  {props.userName
                    ? `${props.userName.substr(
                        0,
                        3
                      )}xxxx${props.userName.substr(
                        props.userName.search("@")
                      )}`
                    : "abcxxxx@veroscredit.com"}
                </span>
              </span>
            </div>
          </div>
          {props.phoneNumber &&
          formatUSMobileNumber(props.phoneNumber) !== "" ? (
            <div className="col-md-12 col-lg-12 col-12">
              <div
                className="cursorPointer authMargin row authDashed"
                onClick={() => {
                  props.smsMfaType();
                }}
              >
                <span>
                  <i className="authContAlign fas fa-mobile" title="SMS"></i>
                </span>
                <span className="d-block fw-semibold text-start ml-4">
                  <span className="text-dark fw-bold d-block fs-3">
                    One-time password (SMS)
                  </span>
                  <span className="text-muted fw-semibold fs-6">
                    {props.phoneNumber
                      ? formatUSMobileNumber(props.phoneNumber)
                      : "(xxx) xxx-1234"}
                  </span>
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12 col-lg-12 col-12">
            <br></br>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
});
export default MfaPopup;
