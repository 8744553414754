import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { jq } from "../../../../common/dataTable/imports";
import {
  customErrorMsg,
  customInvalidMsg,
  usPhoneNoFormat,
} from "../../../../common/helperContainer";
import NumberFormat from "react-number-format";
import CircleSpinner from "../../../../common/loader/circleSpinner";

const AddPhone = React.memo((props: any) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumError, setPhoneNumError] = useState("");

  const validatePhoneNum = (number: string) => {
    if (!number) {
      return customErrorMsg;
    } else if (!usPhoneNoFormat.test(number)) {
      return customInvalidMsg;
    } else {
      return "";
    }
  };

  const handlePhoneNumber = (e: any) => {
    setPhoneNumber(e.target.value);
    const errMsg = validatePhoneNum(e.target.value);
    setPhoneNumError(errMsg);
  };

  const handleNotificationReq = (e: any) => {
    props.setNotificationReq(e.checked);
    setPhoneNumError("");
  };

  useEffect(() => {
    setPhoneNumber(props.phoneNumber ? props.phoneNumber : "");
    props.setNotificationReq(props.notificationReq);
    setPhoneNumError("");
    if (props.showAddPhone === true) {
      checkAddMobPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showAddPhone]);

  const checkAddMobPopup = () => {
    let innerHeight = window.innerHeight;
    let popupHeight = document.getElementById("modalAddMobPopup");
    let calHeight = popupHeight?.clientHeight ? popupHeight?.clientHeight : 0;
    let diffHeight = innerHeight - calHeight;

    if (diffHeight <= 0) {
      jq("#modalAddMobPopup").css("margin-top", "100px");
    } else {
      jq("#modalAddMobPopup").css("margin-top", `${diffHeight / 2}px`);
    }
  };

  const handleCancelClose = () => {
    props.setIsLoader(true);
    props.setAddPhone(false);
    props.setNotificationReq(true);
    props.cancelTempMob(phoneNumber, true);
  };

  const addTempMobile = () => {
    const errMsg = validatePhoneNum(phoneNumber);

    if (errMsg === "" && props.notificationReq === false) {
      props.setIsLoader(true);
      //Save new mobile number and send mobile otp
      props.saveTempMob(phoneNumber, props.notificationReq, false);
    } else if (phoneNumber === "" && props.notificationReq === true) {
      props.setIsLoader(true);
      //Marking as notifications not required
      props.saveTempMob(phoneNumber, props.notificationReq, false);
    } else if (errMsg !== "" && props.notificationReq === true) {
      setPhoneNumError(errMsg);
    } else if (phoneNumber !== "" && props.notificationReq === true) {
      //  Entering the new mobile number and marking notification as not required, then we need to go with the adding new mobile work flow
      //  To go with the adding mobile number work flow, we have to change the notification required as false
      props.setIsLoader(true);
      //Save new mobile number and send mobile otp
      props.saveTempMob(phoneNumber, false, true);
    } else if (errMsg !== "" && props.notificationReq === false) {
      setPhoneNumError(errMsg);
    }
  };

  return (
    <div>
      {/* Add Phone popup */}
      <Modal
        backdrop="static"
        show={props.showAddPhone}
        onHide={() => {
          props.setAddPhone(false);
        }}
        id="modalAddMobPopup"
        className="popupDefaultRightPadding"
      >
        <div
          className={`${
            props.isLoader === true ? "loaderGrey" : "loaderDispaly"
          }`}
        >
          <CircleSpinner />
        </div>
        <Modal.Header>
          <div>
            <h5
              className="text-dark fw-bold d-block fs-3"
              style={{ fontWeight: 400, marginBottom: "0px" }}
            >
              Mobile Phone Number Needed
            </h5>
          </div>
          <div className="commentPopupClose">
            <button
              type="button"
              className="pr-0 popupCloseNew"
              onClick={() => {
                handleCancelClose();
              }}
            >
              <i className="la la-remove" style={{ fontSize: "large" }}></i>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="p-model-body">
          <div className="col-md-12 col-lg-12 col-12">
            <div className="col-md-12 col-lg-12 col-12 mt-4 mb-6">
              <h6 className="text-muted fs-6">
                Please add a mobile phone number to have more options to
                authenticate
              </h6>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-12">
            <div className="col-md-12 col-lg-12 col-12">
              <ol style={{ marginLeft: "-25px" }} className="text-muted fs-6">
                <li>
                  Enter your mobile phone number below and press the "Submit"
                  button
                </li>
                <li>
                  A 6-digit verification code will be sent to your mobile phone.
                  When you receive that number, enter it as the verification
                  code
                </li>
              </ol>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-12 mt-4">
            <div className="col-md-12 col-lg-12 col-12 mt-4 mb-2">
              <input
                type="checkbox"
                checked={props.notificationReq}
                onChange={(e) => handleNotificationReq(e.target)}
                className="filled-in"
                name="notificationReq"
              />
              <span className="text-muted fs-6 ml-3">
                Don't ask again on this computer
              </span>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-12 mt-4">
            <div
              className="row col-md-12 col-lg-12 col-12"
              style={{ justifyContent: "center" }}
            >
              <div>
                <i
                  className="addMobPopAlign fas fa-mobile mr-8"
                  title="Enter mobile number"
                ></i>
              </div>
              <div className="mt-2 mb-6">
                <NumberFormat
                  type="text"
                  placeholder="(XXX) XXX-XXXX"
                  autoComplete="off"
                  name="phoneNumber"
                  className="form-control form-control-sm"
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                  format="(###) ###-####"
                  style={{ width: "18rem", height: "3rem" }}
                />
                <span className="errorMsg">
                  {phoneNumError ? <div>{phoneNumError}</div> : null}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-12">
            <div className="col-md-12 col-lg-12 col-12 mb-4 text-center">
              <button
                type="button"
                style={{ width: "30%" }}
                className="btn btn-secondary m-btn m-btn--custom m-btn--icon"
                onClick={handleCancelClose}
              >
                Cancel
              </button>
              <button
                type="button"
                style={{ width: "30%" }}
                className="btn btn-focus m-btn m-btn--custom m-btn--icon ml-4"
                onClick={addTempMobile}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
});
export default AddPhone;
